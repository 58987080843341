/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*  monospace;*/
/*}*/

a {
    text-decoration: none;
}

@keyframes glow {
    0% { 
        box-shadow: 0px 30px 80px rgb(138 38 255 / 40%); 
    
    }
    50% { 
        box-shadow: 0px 30px 80px rgb(138 38 255 / 20%); 
      
    }
    100% { 
        box-shadow: 0px 30px 80px rgb(138 38 255 / 40%); 
        
    }
}

@font-face {
    font-family: 'Quantico';
    font-weight: 400;
    src: url(/src/fonts/Quantico-Regular.ttf);
}

@font-face {
    font-family: 'Quantico';
    font-weight: 700;
    src: url(/src/fonts/Quantico-Bold.ttf);
}

@font-face {
    font-family: 'Open Sans';
    src: url(/src/fonts/OpenSans-VariableFont_wdth\,wght.ttf);
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url(/src/fonts/OpenSans-Italic-VariableFont_wdth\,wght.ttf);
    font-style: italic;
}

@font-face {
    font-family: 'Manrope';
    src: url(/src/fonts/Manrope-VariableFont_wght.ttf);
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url(/src/fonts/Montserrat-Italic.ttf);
    font-style: italic;
}