.desktopExplorePreview {
    width: 100%;
    height: 100%;
}

.desktopExplorePreview .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.12);
}

.desktopExplorePreview .swiper-pagination-bullet-active {
    background-color: rgba(255, 255, 255, 1);
}
.desktopExplorePreview .swiper-button-next, .swiper-button-prev {
    color: rgba(255, 255, 255, 1);
}
.desktopExplorePreview .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 20px;
    font-weight: 700;
}
 .desktopExplorePreview .swiper-slide {
    border-radius: 15px;
    text-align: center;
    font-size: 18px;
    background: #0f1224;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

 .desktopExplorePreview .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.desktopExplorePreview .swiper-button-prev, .desktopExplorePreview .swiper-button-next {
    width: 40px;
    height: 40px;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    position: absolute;
    top: 50%;
    z-index: 2;
    border-radius: 50%;
    box-shadow: rgb(0 0 0 / 0%) 0px 4px 10px;
    transition: all 0.3s ease-in-out;
}
.desktopExplorePreview .swiper-button-prev:hover, .desktopExplorePreview .swiper-button-next:hover {
    background-color: rgba(255, 255, 255, 0.12);
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 10px;
    transform: scale(1.05);
}

@media only screen and (max-width: 800px) {

    .desktopExplorePreview .swiper-button-prev, .desktopExplorePreview .swiper-button-next {
        display: none;
    }
}