
.previewSwiper .swiper {
    width: 100%;
    height: 100%;
}
.previewSwiper .swiper-pagination-bullet-active {
    background-color: #944DFF
}
.previewSwiper .swiper-button-next, .swiper-button-prev {
    color: #944DFF
}
.previewSwiper .swiper-button-next:after, .swiper-button-prev:after {
     font-size: 20px;
}
 .previewSwiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

 .previewSwiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
