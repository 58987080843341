
.newsSwiper {
    padding-bottom: 60px;
}
@media only screen and (max-width: 600px) {
    .newsSwiper {
        padding-bottom: 50px;
    }
}

.newsSwiper .swiper-pagination-bullet-active {
    background-color: #fff !important;
}
.newsSwiper .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, 0.85);
}
.newsSwiper .swiper-button-next {
    color: rgba(255, 255, 255, 0.85);
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 100px;
    height: 34px;
    width: 34px;
    margin-right: -10px;
}
.newsSwiper .swiper-button-prev {
    color: rgba(255, 255, 255, 0.85);
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 100px;
    height: 30px;
    width: 30px;
    margin-left: -10px;
}
.newsSwiper .swiper-button-next:after {
    font-size: 15px;
    font-weight: 900;
    margin-left: 3px;
}
.newsSwiper .swiper-button-prev:after {
    font-size: 15px;
    font-weight: 900;
    margin-right: 4px;
}
 .newstSwiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

 .newsSwiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
